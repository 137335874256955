import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Observable } from 'rxjs';
import { Topic } from './topic/topic';
import { TopicService } from './topic/topic.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedTopic;
  public topics: Observable<Topic[]>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private topicService : TopicService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.topics = this.topicService.getTopics();
  }

  isSelected(topicName : string) {
    const path = window.location.pathname.split('topic/')[1];
    if(topicName && path && topicName.toLowerCase() === path.toLowerCase()) {
      this.selectedTopic = topicName.toLowerCase();
      return true;
    }
    return false;
  }

}
