import { Injectable } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

@Injectable({
  providedIn: 'root'
})
export class FingerprintService {
  agent;

  visitorId;

  constructor() { }

  async InitAgent() {
    this.agent = await FingerprintJS.load({
      apiKey: 'eXGvgWf6eCLAHO4emgB0', region: 'eu',
      endpoint: 'https://metrics.n7cloud.com'
    });
    //this.visitorId = await this.agent.get();

  }

  // make it getter to get the fingerprint
  async Fingerprint() {
    const fingerprint = await this.agent.get()
    return fingerprint.visitorId;
  }
}
