import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ToastController } from '@ionic/angular';
import { Observable, pipe } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Topic } from './topic';
import { WordPair } from './word-list/word-pair';

@Injectable({
  providedIn: 'root'
})
export class TopicService {
  private topicsCollection: AngularFirestoreCollection<Topic>;

  constructor( 
      private db: AngularFirestore,
      private storage: AngularFireStorage,
      public toastController: ToastController
    ) { }

  getTopics(): Observable<Topic[]> {
    this.topicsCollection = this.db.collection<Topic>('topics');
    return this.topicsCollection.valueChanges({idField: 'name'});
  }

  getWordlist(topicName : string): Observable<WordPair[]> {
    const topicsCollection = this.db.collection<WordPair>(`topics/${topicName}/wordlist`);
    return topicsCollection.valueChanges({idField: 'id'});
  }

  removeNewFromTopic(topicName : string) {
    const request$ = this.db.collection(`topics/${topicName}/wordlist`, ref => ref.where('new', '==', true)).get();

    const removeElement = (docs: QuerySnapshot<any>) => {
        docs.docs.forEach(e => {
          e.ref.delete();
        });
      }
    request$.subscribe(removeElement);
  }

  uploadFile(file, path) {
    const task = this.storage.upload(path, file);
    return task;
  }

  uploadDataUrl(str, path) {
    const ref = this.storage.ref(path);
    const task = ref.putString(str, 'data_url');
    return task;
  }
}
